[class*=illustration-] {
	position: relative;

	&::after {
		pointer-events: none;
	}
}

[class*=illustration-element-]::after {
	z-index: -1;
}

[class*=illustration-section-]::after {
	z-index: -2;
}

// Hero illustration 01
.illustration-section-01::after {
	@include illustration('illustration-section-01-facu.png', 80%, 120%, center);
	// @include illustration('fondohome.jpg', 90%, 90%, center);
}

// Hero illustration 02
.illustration-section-02::after {
	@include illustration('illustration-section-02.svg', 100%, 612px, top);
}